import React, { useState } from 'react';
import { Input, Button, Form, message, Space } from 'antd';
import { SendOutlined, CheckCircleOutlined, MailOutlined } from '@ant-design/icons';

const EmailCard = ({ color, onSubmit, isEmailSubmitted, setIsEmailSubmitted }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (value && !validateEmail(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = async () => {
    if (isEmailSubmitted || !email) {
      return; // Do nothing if already submitted or email is empty
    }

    if (!validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    const success = await onSubmit(email); // Call the parent component's handleSubmit function
    if (success) {
      setIsEmailSubmitted(true);
    }
    setLoading(false);
  };

  return (
    <Form.Item 
      style={{  padding: '10px', borderRadius: '2px', marginBottom: '2px' ,width:'100%'}}
      validateStatus={emailError ? "error" : ""}
      help={emailError}
    >
      <Input
        style={{ 
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: emailError ? 'red' : undefined
        }}
        placeholder="Enter your email"
        value={email}
        onChange={handleEmailChange}
        suffix={
          <Button
            style={{ 
              background: color, 
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              height: 37
            }}
            onClick={handleSubmit}
            icon={!isEmailSubmitted ? <SendOutlined /> : <CheckCircleOutlined />}
            disabled={isEmailSubmitted}
            loading={loading}
          >
            {isEmailSubmitted ? "" : ""}
          </Button>
        }
        prefix={<MailOutlined />}
        required
        disabled={isEmailSubmitted}
      />
    </Form.Item>
  );
};

export default EmailCard;
