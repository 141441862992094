import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const Appcss=`
.scroll-wrapper {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    -ms-overflow-style: auto;
  }
  
  .scroll-wrapper::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  .scroll-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .flex-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
  }
  
  .bouncing-loader {
    display: flex;
    justify-content: center;
    padding-block: 5px;
  }
  
  .bouncing-loader > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  
  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-16px);
    }
  }
  
  .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @media (max-width: 768px) {
    .scroll-wrapper {
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .flex-row {
      justify-content: flex-start;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes slideOut {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }
  
  
  
  
  
  
  .fixed {
    position: fixed;
  }
  
  .bottom-4 {
    bottom: 1rem;
  }
  
  .right-4 {
    right: 1rem;
  }
  
  .p-4 {
    padding: 1rem;
  }
  
  .bg-white {
    background-color: white;
  }
  
  .shadow-lg {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  
  .rounded-lg {
    border-radius: 0.5rem;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .font-semibold {
    font-weight: 600;
  }
  
  .flex {
    display: flex;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  
  .mr-2 {
    margin-right: 0.5rem;
  }
  
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .bg-blue-500 {
    background-color: #4299e1;
  }
  
  .text-white {
    color: white;
  }
  
  .rounded {
    border-radius: 0.25rem;
  }
  
  .hover\:bg-blue-600:hover {
    background-color: #3182ce;
  }
  
  .transition-colors {
    transition: background-color 0.2s;
  }



  .typing-indicator-dot {
    display: flex;
    justify-content: center;
    padding-block: 5px;
  }
  
  .typing-indicator-dot > div {
    width: 8px;
    height: 8px;
    border: 2px solid rgb(127, 40, 248);
    border-radius: 50%;
    opacity: 1;
    animation: typing-indicator-dot 0.8s infinite alternate;
  }
  
  @keyframes typing-indicator-dot {
    to {
      opacity: 0.1;
      transform: translateY(-16px);
    }
  }
  
  .typing-indicator-dot  > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-indicator-dot > div:nth-child(3) {
    animation-delay: 0.4s;
  }




  
`
const ShadowWrapper = ({ children }) => {
  const shadowRootRef = useRef(null);

  useEffect(() => {
    if (!shadowRootRef.current.shadowRoot) {
      const shadowRoot = shadowRootRef.current.attachShadow({ mode: 'open' });

      // Inject Ant Design and Tailwind CSS into the Shadow DOM
      const antdStyleSheet = document.createElement('link');
      antdStyleSheet.rel = 'stylesheet';
      antdStyleSheet.href = 'https://cdnjs.cloudflare.com/ajax/libs/antd/4.16.13/antd.min.css'; // Ant Design CDN link

      const tailwindStyleSheet = document.createElement('link');
      tailwindStyleSheet.rel = 'stylesheet';
      tailwindStyleSheet.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css'; // Tailwind CDN link

      const antdScript = document.createElement('script');
      antdScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/antd/5.20.6/antd.min.js'; // Ant Design JS CDN link
      antdScript.async = true;
      const styleSheet = document.createElement('style');
      styleSheet.textContent = Appcss;
      
      shadowRoot.appendChild(styleSheet);

      shadowRoot.appendChild(antdStyleSheet);  // Inject Ant Design styles
      shadowRoot.appendChild(tailwindStyleSheet); // Inject Tailwind CSS styles
      shadowRoot.appendChild(antdScript); // Inject Ant Design JS
    }
  }, []);

  return (
    <div ref={shadowRootRef}>
      {shadowRootRef.current && shadowRootRef.current.shadowRoot
        ? ReactDOM.createPortal(children, shadowRootRef.current.shadowRoot)
        : null}
    </div>
  );
};

export default ShadowWrapper;
