
import React from 'react'

export default function BouncingDotsLoaderTyping({customerSupport,borderColor}) {

  return (
    <div  className="typing-indicator-dot">
    <div style={{borderColor:borderColor}}></div>
    <div style={{borderColor:borderColor}}></div>
    <div style={{borderColor:borderColor}}></div>
    {customerSupport?
        <p style={{color:'gray'}}>
       {customerSupport}is typing ...
    </p>
    :<></>
    }


  </div>
  );
}
